import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import Box from 'shopper/components/Box';
import Heading from 'shopper/components/Heading';

const SidebarBox = forwardRef(
  ({ children, title, className, ...rest }, ref) => (
    <Box ref={ref} className={twMerge('px-4 pt-4', className)} {...rest}>
      <Heading as="p" className="mb-4" size="size6">
        {title}
      </Heading>
      {children}
    </Box>
  )
);

SidebarBox.displayName = 'SidebarBox';

SidebarBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  title: PropTypes.string.isRequired,
};

export default SidebarBox;
