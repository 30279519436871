import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { forwardRef, memo } from 'react';

import Link from 'shopper/components/Link';

import OffersListItem from 'components/OffersListItemCard';

import { sendEvent } from 'lib/gtag';

import SidebarBox from './SidebarBox';

const Loading = dynamic(() => import('./Loading'), { ssr: false });

const GA_EVENT_CATEGORY = 'sidebar_highlights';

const onOfferClick = () => {
  sendEvent({ action: 'click_offer', category: GA_EVENT_CATEGORY });
};

const onLinkClick = () => {
  sendEvent({ action: 'all_offers', category: GA_EVENT_CATEGORY });
};

const OffersList = forwardRef(
  ({ isLoading = false, linkProps, offers, title, ...rest }, ref) => {
    if (!isLoading && (!offers || offers.length === 0)) {
      return null;
    }

    return (
      <SidebarBox ref={ref} title={title} {...rest}>
        {!isLoading ? (
          <>
            {offers.map((offer, index) => (
              <OffersListItem
                data-test-selector="view-offer-item"
                onOffersItemClick={onOfferClick}
                {...offer}
                key={`${offer.offerId}-${index}`}
              />
            ))}
            <div className="flex w-full items-center justify-center py-4">
              <Link size="size1" onClick={onLinkClick} {...linkProps} />
            </div>
          </>
        ) : (
          <Loading className="h-[500px]" />
        )}
      </SidebarBox>
    );
  }
);

OffersList.displayName = 'OffersList';

OffersList.propTypes = {
  isLoading: PropTypes.bool,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      offerComments: PropTypes.number.isRequired,
      offerIsHighlight: PropTypes.bool.isRequired,
      ratings: PropTypes.shape().isRequired,
      offerOldPrice: PropTypes.number,
      offerPhoto: PropTypes.string.isRequired,
      offerPrice: PropTypes.number.isRequired,
      offerPriceType: PropTypes.string.isRequired,
      offerPublished: PropTypes.string.isRequired,
      offerSlug: PropTypes.string.isRequired,
      offerStatusName: PropTypes.string.isRequired,
      offerTags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        })
      ).isRequired,
      offerTitle: PropTypes.string.isRequired,
      storeDomain: PropTypes.string,
      storeImage: PropTypes.string,
      storeName: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  linkProps: PropTypes.shape(),
};

export default memo(OffersList);
